<template>
  <div class="inovation" data-view>
    <b-row class="rowas topBanner">
      <div class="topImg">
          <img loading="lazy" alt="empresas" src="https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/pageheads/empresas.jpg" style="width: 100%" />
      </div>
    </b-row>
    <div class="container">
      <div class="title">
        <b-row class="rowas">
          <div class="col-12 col-sm-9 col-md-8 noPadding plusAncho">
            <p style="font-size: 34px;"><img loading="lazy" alt="lupa" src="https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/icons/lupa-executive2.png">{{ $t('message.the') }} <span>{{ $t('message.innovation') }}</span></p>
          </div>
        </b-row>
      </div>
      <b-row class="rowas">
        <p style="font-size: 22px;">
          La innovación no pide permisos llega y cambia los estatus de las cosas. 
          Los gobiernos no la pueden parar, las corporaciones no la pueden ignorar, 
          las empresas no importa el tamaño que tengan sin innovación no podrán 
          sobrevivir, los profesionales que no se adapten estarán fuera del 
          mercado laboral. 
        </p>
      </b-row>
      <b-row class="rowas reorder">
        <div class="col-md-8 textCol right">
          <hr>
          <p>
            Todos, personas, sociedad y empresa, estamos inmersos en un cambio de era 
            y los gestores del mundo empresarial tienen actualmente la llave en su mano 
            para  poder ser esa palanca de cambio, el puente para ayudar a transformar 
            organizaciones en un aparato más inteligente y dinámico capaz de sobrevivir 
            y crecer en tiempos turbulentos.
          </p>
        </div>
        <div class="col-md-4 textIcon">
          <img loading="lazy" alt="icon" class="inovIcon" src="https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/icons/inovation/bank.png">
        </div>
      </b-row>
      <b-row class="rowas">
        <div class="col-md-4 textIcon">
          <img loading="lazy" alt="icon" class="inovIcon" src="https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/icons/inovation/report.png">
        </div>
        <div class="col-md-8 textCol">
          <hr>
          <p>
            Hay una cosa que está clara y seguir haciendo lo mismo como se ha hecho siempre 
            no aporta nada nuevo, por eso, el reto de un gestor sea laboral, fiscal o de otra 
            índole es ayudar al cliente a tomar decisiones más estratégicas, mejor planificadas, 
            pero sin duda este no ha sido el papel de gestorías en el pretérito, pero será decisivo 
            para el éxito del gestor en el presente y en el futuro. 
          </p>
        </div>
      </b-row>
      <b-row class="rowas reorder">
        <div class="col-md-8 textCol right">
          <hr>
          <p>
            El reto actual del mundo empresarial  pasa por manos de expertos que le asesoren sobre 
            el uso de nuevas herraminta, en este caso el gestor debe ser capaz de redireccionar al cliente 
            al crecimiento. Para ello el gestor o asesor pasa por un nuevo concepto de negocio y aprendizaje. 
            El reto es ser un canal de soporte para el cliente dónde pueda canalizar valor  e intermediar en 
            su gestión como clave del éxito tanto suya como en la del cliente.
          </p>
        </div>
        <div class="col-md-4 textIcon">
          <img loading="lazy" alt="icon" class="inovIcon" src="https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/icons/inovation/education.png">
        </div>
      </b-row>
      <b-row class="rowas">
        <div class="col-md-4 textIcon">
          <img loading="lazy" alt="icon" class="inovIcon" src="https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/icons/inovation/podium.png">
        </div>
        <div class="col-md-8 textCol">
          <hr>
          <p>
            Un desafío sin duda que representa el mayor aprendizaje  en el mundo de la gestión. 
            Pasamos de ser expertos en declarar impuestos, hacer nóminas o funcionarios sin sueldo 
            de las administraciones públicas  para convertirnos en  gestores expertos, capaces de 
            manejar la incertidumbre y el cambio, asistiendo al cliente en su proceso de transformación 
            a una nueva era del trabajo y de gestión, dónde la innovación no se compone únicamente del 
            armazón de la tecnología si no en esencia de comprender todos los cambios que convergen en 
            la actualidad hacia un mundo completamente diferente y hacia una humanidad distinta. 
            Navegamos más allá de la innovación del producto o la innovación en procesos a la 
            innovación de personas. Para el asesor, gestor o intermediario en la gestión empresarial 
            sus acciones estratégicas se medirán por aportar nuevas soluciones al cliente.
          </p>
        </div>
      </b-row>
      <b-row class="clearfix" style="height: 50px;"></b-row>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'inovation',
  metaInfo() {
    return {
      title: this.$t('message.innovation'),
      meta: [
        // OpenGraph
        { vmid: "og:title", property: "og:title",  content: this.$t('message.innovation') + ' - Club de Ejecutivos' },
        { vmid: "og:type", property: 'og:type', content: 'website' },
        // Google / Schema.org markup:
        { itemprop: 'name', content: this.$t('message.innovation') + ' - Club de Ejecutivos' },
      ]
    }
  },
}
</script>

<style scoped>
  .rowas {
    width: 100%;
  }

  .rowas p {
    font-family: 'Open sans', Arial, sans-serif;
  }

  .textCol {
    margin-top: auto;
    margin-bottom: auto;
    text-align: left;
  }

  .textCol.right {
    text-align: right;
  }

  p {
    font-size: 18px;
  }

  .inovIcon {
    height: 170px;
    margin: 25px;
  }

  @media screen and (max-width: 767px) {
    .reorder {
      display: flex;
      flex-direction: column-reverse;
    }

    .container {
      width: 90%;
    }
  }

  .topImg {
    margin-left: auto;
    margin-right: auto;
  }

  .topBanner {
    background: #aaa;
    margin-right: 0px;
    margin-left: 0px;
  }

  .textIcon {
    margin-top: auto;
    margin-bottom: auto;
  }
</style>
